export default function initContactForm() {
    // 1) Get the form and return early if not found
    const form = document.getElementById('contact-form');
    if (!form) return;

    // 2) Grab required elements
    const formContent = form.querySelector('.form-content');
    const successMessage = form.querySelector('.success-message');
    const submitButton = form.querySelector('button[type="submit"]');

    if (!formContent || !successMessage || !submitButton) {
        console.warn('Required form elements not found');
        return;
    }

    // 3) Check if reCAPTCHA has loaded
    let captchaLoaded = false;

    // 4) Ensure recaptcha_response input exists
    let recaptchaInput = document.getElementById('recaptcha_response');
    if (!recaptchaInput) {
        recaptchaInput = document.createElement('input');
        recaptchaInput.type = 'hidden';
        recaptchaInput.id = 'recaptcha_response';
        recaptchaInput.name = 'recaptcha_response';
        form.appendChild(recaptchaInput);
    }

    // 5) HONEYPOT field (hidden)
    //    We create or ensure a hidden <input> that bots might fill:
    let honeyPotInput = document.getElementById('my_honey');
    if (!honeyPotInput) {
        honeyPotInput = document.createElement('input');
        honeyPotInput.type = 'text';
        honeyPotInput.id = 'my_honey';
        honeyPotInput.name = 'my_honey';
        honeyPotInput.style.display = 'none'; // or 'visibility: hidden'
        form.appendChild(honeyPotInput);
    }

    // 6) TIME-BASED field
    //    We'll capture the form load time in a hidden field:
    let formLoadTSInput = document.getElementById('form_load_ts');
    if (!formLoadTSInput) {
        formLoadTSInput = document.createElement('input');
        formLoadTSInput.type = 'hidden';
        formLoadTSInput.id = 'form_load_ts';
        formLoadTSInput.name = 'form_load_ts';
        formLoadTSInput.value = Math.floor(Date.now() / 1000).toString();
        form.appendChild(formLoadTSInput);
    }

    // 7) Lazy-load reCAPTCHA
    function loadRecaptcha() {
        if (captchaLoaded) return;
        captchaLoaded = true;

        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?render=6LcXEcsbAAAAACI79HkuXwvBm-ap6pOItplTXoZ5'; // Replace with your site key
        script.async = true;
        script.defer = true;
        script.onload = () => console.log('reCAPTCHA script loaded');
        document.body.appendChild(script);
    }

    // 8) Attach listeners to form fields to trigger reCAPTCHA load
    const inputs = form.querySelectorAll('input, textarea');
    inputs.forEach(input => {
        input.addEventListener('focus', loadRecaptcha, { once: true });
    });

    // 9) Form Submit Handler
    form.addEventListener('submit', async function(e) {
        e.preventDefault();

        // Verify that our AJAX object is configured
        if (typeof ajax_object === 'undefined' || !ajax_object.ajax_url) {
            showErrorMessage('Configuration error. Please try again later.');
            return;
        }

        // Store original button text for resetting
        const originalButtonText = submitButton.textContent;
        
        // Update button state
        try {
            submitButton.textContent = 'Sending...';
            submitButton.disabled = true;
        } catch (error) {
            console.error('Error updating button state:', error);
        }

        // Make sure reCAPTCHA is loaded
        if (!captchaLoaded || typeof grecaptcha === 'undefined') {
            showErrorMessage('Captcha not initialized. Please try again.');
            resetButtonState(originalButtonText);
            return;
        }

        // Execute reCAPTCHA
        grecaptcha.ready(async function() {
            try {
                const token = await grecaptcha.execute('6LcXEcsbAAAAACI79HkuXwvBm-ap6pOItplTXoZ5', { action: 'submit' });
                recaptchaInput.value = token;

                // Now submit the form via AJAX
                await submitForm(originalButtonText);
            } catch (error) {
                console.error('reCAPTCHA error:', error);
                showErrorMessage('An error occurred while verifying reCAPTCHA. Please try again.');
                resetButtonState(originalButtonText);
            }
        });
    });

    // 10) Actual Submit via AJAX
    async function submitForm(originalButtonText) {
        // Collect all form data
        const formData = new FormData(form);
        // Add our WP AJAX action name
        formData.append('action', 'contact_form_submission');

        // Convert FormData to URLSearchParams for x-www-form-urlencoded
        const data = new URLSearchParams();
        try {
            for (const [key, value] of formData) {
                data.append(key, value);
            }
        } catch (error) {
            console.error('Error converting FormData:', error);
            resetButtonState(originalButtonText);
            return;
        }

        // Send the request
        try {
            const response = await fetch(ajax_object.ajax_url, {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: data
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();

            if (responseData.success) {
                // Fade out form
                formContent.style.opacity = '0';
                formContent.style.transition = 'opacity 0.3s ease';

                setTimeout(() => {
                    try {
                        // Show success
                        successMessage.classList.remove('hidden');
                        successMessage.style.opacity = '0';
                        successMessage.offsetHeight; // Trigger reflow
                        successMessage.style.transition = 'opacity 0.3s ease';
                        successMessage.style.opacity = '1';

                        // Reset the actual form fields
                        form.reset();
                    } catch (error) {
                        console.error('Error showing success message:', error);
                        showErrorMessage();
                    }
                }, 300);
            } else {
                // Show server error message or fallback
                showErrorMessage(responseData.data);
                // If CAPTCHA error, reset
                if (responseData.data?.includes('CAPTCHA')) {
                    grecaptcha.reset();
                }
            }
        } catch (error) {
            console.error('Error:', error);
            showErrorMessage();
        } finally {
            resetButtonState(originalButtonText);
        }
    }

    // 11) Helper functions
    function resetButtonState(originalButtonText) {
        try {
            submitButton.textContent = originalButtonText;
            submitButton.disabled = false;
        } catch (error) {
            console.error('Error resetting button state:', error);
        }
    }

    function showErrorMessage(message = 'There was an error sending your message. Please try again.') {
        try {
            alert(message);
        } catch (error) {
            console.error('Error showing error message:', error);
        }
    }
}

export default function initPopup() {
    // Toggle this to enable/disable cookie consent logic
    const COOKIE_CONSENT_ENABLED = true;

    try {
        const signupForm = document.querySelector('.newsletter-signup');
        const collapseButton = document.getElementById('collapse-button');
        const fakeHeader = document.getElementById('fake-header');
        const acceptCookieButton = document.getElementById('ap');

        if (!signupForm) return;

        // Utility functions
        function setCookie(name, value, days) {
            try {
                const date = new Date();
                date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
                document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
            } catch (error) {
                console.error('Error setting cookie:', error);
            }
        }

        function getCookie(name) {
            try {
                const nameEQ = `${name}=`;
                const cookies = document.cookie.split(';');
                for (let c of cookies) {
                    c = c.trim();
                    if (c.indexOf(nameEQ) === 0) {
                        return c.substring(nameEQ.length);
                    }
                }
                return null;
            } catch (error) {
                console.error('Error getting cookie:', error);
                return null;
            }
        }

        // If cookie consent is disabled, treat hasConsent as true
        let hasConsent = COOKIE_CONSENT_ENABLED
            ? getCookie('cookieConsent') === 'true'
            : true;
        const isCollapsed = getCookie('newsletter_collapsed') === 'true';

        // Always start collapsed
        signupForm.classList.remove('expanded');

        function handleNewsletterExpansion() {
            if (COOKIE_CONSENT_ENABLED) {
                hasConsent = getCookie('cookieConsent') === 'true';
            } else {
                // If consent is disabled, we treat it as always true
                hasConsent = true;
            }
            if (hasConsent && !isCollapsed) {
                setTimeout(() => {
                    signupForm.classList.add('expanded');
                }, 5000);
            }
        }

        // Initial check
        handleNewsletterExpansion();

        // Cookie consent button handler
        if (acceptCookieButton && COOKIE_CONSENT_ENABLED) {
            acceptCookieButton.addEventListener('click', function() {
                setCookie('cookieConsent', 'true', 365);
                hasConsent = true;
                handleNewsletterExpansion();
            });
        }

        // Expand button handler
        if (fakeHeader) {
            fakeHeader.addEventListener('click', function(e) {
                e.stopPropagation();
                // Only allow expansion if cookie consent exists (or is disabled)
                if (hasConsent) {
                    signupForm.classList.add('expanded');
                    setCookie('newsletter_collapsed', 'false', 30);
                }
            });
        }

        // Collapse button handler
        if (collapseButton) {
            collapseButton.addEventListener('click', function(e) {
                e.stopPropagation();
                signupForm.classList.remove('expanded');
                setCookie('newsletter_collapsed', 'true', 30);
                if (typeof gtag === "function") {
                    gtag('event', 'popup_dismiss', {
                        'event_category': 'engagement',
                        'event_label': 'Popup Banner'
                    });
                }
            });
        }

    } catch (error) {
        console.error('Error in initPopup:', error);
    }
}

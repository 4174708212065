export default function initNewsletterSignup() {
    try {
        const form = document.querySelector('.signup-form');
        if (!form) return;

        const successMessage = document.querySelector('.signup-success-message');
        if (!successMessage) return;

        const isInPopup = form.closest('.newsletter-signup') !== null;
        const popup = isInPopup ? form.closest('.newsletter-signup') : null;

        // Add the style for spinner
        const style = document.createElement('style');
        style.textContent = `
            @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }
            .spinner {
                display: inline-block;
                width: 1em;
                height: 1em;
                margin-right: 0.5em;
                vertical-align: middle;
                border: 2px solid currentColor;
                border-right-color: transparent;
                border-radius: 50%;
                animation: spin 0.75s linear infinite;
            }
        `;
        document.head.appendChild(style);

        // Create error container
        const errorContainer = document.createElement('div');
        errorContainer.className = 'error-message text-red-600 mb-4 hidden';
        form.insertBefore(errorContainer, form.firstChild);
        
        let captchaLoaded = false;

        function loadRecaptcha() {
            if (captchaLoaded) return;
            captchaLoaded = true;

            const script = document.createElement('script');
            script.src = 'https://www.google.com/recaptcha/api.js?render=6LcXEcsbAAAAACI79HkuXwvBm-ap6pOItplTXoZ5';
            script.async = true;
            script.defer = true;
            script.onload = () => {
                console.log('reCAPTCHA script loaded.');
            };
            script.onerror = () => {
                console.error('Failed to load reCAPTCHA script.');
            };
            document.body.appendChild(script);
        }

        function setLoadingState(button, isLoading) {
            if (isLoading) {
                const spinner = document.createElement('span');
                spinner.className = 'spinner';
                button.disabled = true;
                button.textContent = 'Submitting...';
                button.prepend(spinner);
            } else {
                button.textContent = 'Sign Up';
                button.disabled = false;
            }
        }

        // Attach event listeners to form inputs
        const inputs = form.querySelectorAll('input');
        inputs.forEach(input => {
            input.addEventListener('focus', loadRecaptcha, { once: true });
        });

         // Handle form submission
        form.addEventListener('submit', async function (e) {
            e.preventDefault();

            const submitButton = form.querySelector('button[type="submit"]');
            if (!submitButton) return;

            errorContainer.classList.add('hidden');
            errorContainer.textContent = '';

             if (!captchaLoaded || typeof grecaptcha === 'undefined') {
                showError('Captcha has not been initialized. Please try again.');
                return;
            }

            setLoadingState(submitButton, true); // Set loading state

                try {
                    console.log('Attempting to execute grecaptcha...');
                    const token = await grecaptcha.execute('6LcXEcsbAAAAACI79HkuXwvBm-ap6pOItplTXoZ5', { action: 'submit' });
                    console.log('reCAPTCHA token:', token);

                    // Set token to hidden input
                    const recaptchaInput = document.getElementById('recaptcha_response');
                    if (!recaptchaInput) {
                        throw new Error('reCAPTCHA input not found');
                    }
                    recaptchaInput.value = token;

                    // Proceed with form submission logic
                    await submitForm(token);
                } catch (error) {
                    console.error('reCAPTCHA error:', error);
                    showError('An error occurred while verifying reCAPTCHA. Please try again.');
                } finally {
                    setLoadingState(submitButton, false); // Reset loading state
                }
            });

        // Add the submitForm function
        async function submitForm(token) {
            try {
                // Get form inputs and validate them
                const firstNameInput = document.querySelector('input[name="newsletter_first_name"]');
                const lastNameInput = document.querySelector('input[name="newsletter_last_name"]');
                const emailInput = document.querySelector('input[name="newsletter_email"]');

                if (!firstNameInput || !lastNameInput || !emailInput) {
                    showError('Required form inputs not found');
                    return;
                }

                const firstName = firstNameInput.value.trim();
                const lastName = lastNameInput.value.trim();
                const email = emailInput.value.trim();

                const namePattern = /^[A-Za-z]+$/;
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                if (!firstName || !lastName || !email) {
                    showError('Please fill in all fields.');
                    return;
                }

                if (!namePattern.test(firstName) || !namePattern.test(lastName)) {
                    showError('Please use only letters in the First Name and Last Name fields.');
                    return;
                }

                if (!emailPattern.test(email)) {
                    showError('Please enter a valid email address.');
                    return;
                }

                if (typeof ajax_object === 'undefined' || !ajax_object.ajax_url) {
                    throw new Error('Ajax configuration not found');
                }

                // Prepare data for AJAX request
                const data = new URLSearchParams();
                data.append('action', 'newsletter_signup');
                data.append('newsletter_first_name', firstName);
                data.append('newsletter_last_name', lastName);
                data.append('newsletter_email', email);
                data.append('recaptcha_response', token);

                // Send AJAX request to the server
                const response = await fetch(ajax_object.ajax_url, {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    },
                    body: data
                });

                if (!response.ok) throw new Error('Network response was not ok');

                const result = await response.json();

                if (result.success) {
                    handleSuccessfulSubmission();
                } else {
                    showError(result.data);
                    if (result.data?.includes('CAPTCHA')) {
                        grecaptcha.reset();
                    }
                }
            } catch (error) {
                console.error('Newsletter signup error:', error);
                showError('An error occurred. Please try again.');
            }
        }

        function handleSuccessfulSubmission() {
            if (!form || !successMessage) return;

            successMessage.classList.remove('hidden');
             successMessage.focus(); // Focus success message for accessibility

            if (isInPopup && popup) {
                setTimeout(() => {
                    popup.classList.remove('expanded');
                    setCookie('newsletter_collapsed', 'true', 7); // Mark as collapsed after successful signup
                }, 3500);
            }
        }

        function setCookie(name, value, days) {
            try {
                const date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                document.cookie = `${name}=${value || ''}; expires=${date.toUTCString()}; path=/`;
            } catch (error) {
                console.error('Cookie error:', error);
            }
        }
    } catch (error) {
        console.error('Newsletter signup initialization error:', error);
    }
}
